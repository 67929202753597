import React, { useState, useEffect } from "react";
import { IoFlag, IoFlagOutline } from "react-icons/io5";
import ExportCsvDateWise from "./DataExportComponents/ExportDateWise";
import { PERMISSIONS } from "../PermissionsConstant";

// Sample data for demonstration

export const ReviewTimesheetTable = ({
  timesheets,
  setCurrentId,
  setShowFlagForm,
  start_date,
  end_date,
  project,
  username,
}) => {
  const [groupByProject, setGroupByProject] = useState(false);
  const permissions = localStorage.getItem("permissions");
  // Function to group and sum timesheets
  const groupAndSum = () => {
    const groupedData = {};

    timesheets.forEach((entry) => {
      const date = entry?.field_entrydate;
      const project = entry?.field_proj?.projectName;
      const hours = parseFloat(entry?.field_time_spent);

      if (groupByProject) {
        // Group by project
        if (!groupedData[project]) {
          groupedData[project] = { totalHours: 0, entries: [] };
        }
        groupedData[project].totalHours += hours;
        groupedData[project]?.entries.push(entry);
      } else {
        // Group by date
        if (!groupedData[date]) {
          groupedData[date] = { totalHours: 0, entries: [] };
        }
        groupedData[date].totalHours += hours;
        groupedData[date].entries.push(entry);
      }
    });

    return groupedData;
  };

  const groupedData = groupAndSum();

  return (
    <div className="overflow-x-auto">
      <div className="flex justify-between">
        <button
          onClick={() => setGroupByProject(!groupByProject)}
          className="mb-4 px-4 py-2 bg-[#077FC0] text-white rounded"
        >
          {groupByProject ? "Group by Date" : "Group by Project"}
        </button>

        {permissions.includes(
          PERMISSIONS.DATA_FILTERING_REPORTING_PERMISSIONS.EXPORT_DATA
        ) && (
          <ExportCsvDateWise
            jsonData={groupedData}
            start_date={start_date}
            end_date={end_date}
            project={project}
            username={username}
          />
        )}
      </div>
      <table className="min-w-full bg-white border border-gray-300 shadow-lg rounded-lg text-sm ">
        <thead className="bg-gray-200 font-montserrat">
          <tr>
            <th className="border px-4 py-2 text-left font-semibold text-gray-700">
              {groupByProject ? "Project" : "Entry Date"}
            </th>
            <th className="border px-4 py-2 text-left font-semibold text-gray-700">
              Username
            </th>
            <th className="border px-4 py-2 text-left font-semibold text-gray-700">
              Total Time Spent (hrs)
            </th>
            <th className="border px-4 py-2 text-left font-semibold text-gray-700">
              Details
            </th>
          </tr>
        </thead>
        <tbody className="text-sm font-opensans">
          {Object.entries(groupedData).map(([key, value]) => (
            <tr key={key}>
              <td className="border px-4 py-2">
                {groupByProject ? key : new Date(key).toLocaleDateString()}
              </td>
              <td className="border px-4 py-2">
                {value?.entries?.[0]?.uid?.fname}
                {value?.entries?.[0]?.uid?.lname}
              </td>
              <td className="border px-4 py-2">{value?.totalHours}</td>
              <td className="px-4 py-2 flex flex-col justify-center border ">
                {value.entries.map((entry, index) => (
                  <div className="flex items-center capitalize  ">
                    <tr
                      key={entry?._id}
                      className="flex justify-evenly  border-gray-300 py-2 w-full"
                    >
                      {/* Project Name */}
                      <td className="px-4 text-left font-semibold text-gray-800 w-[15rem]">
                        {entry?.field_proj?.projectName || "UNKNOWN"}
                      </td>

                      {/* Task */}
                      <td className="  px-4 text-left text-gray-600 w-[10rem]">
                        {entry?.field_entrytask || "No Task"}
                      </td>

                      {/* Description and Time Spent */}
                      {!entry?.isHtml ? (
                        <td className="px-4 text-left text-gray-500  w-[20rem] text-sm">
                          {entry?.body
                            ? `${entry.body} (${entry?.field_time_spent} hrs)`
                            : "No Description"}
                        </td>
                      ) : (
                        <td className="px-4 text-left text-gray-500  w-[20rem] text-sm">
                          {entry?.body ? (
                            <div className="flex gap-2">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: entry?.body,
                                }}
                              />
                              (${entry?.field_time_spent} hrs)
                            </div>
                          ) : (
                            "No Description"
                          )}
                        </td>
                      )}
                    </tr>
                    <div
                      onClick={() => {
                        setCurrentId(entry?._id);
                        setShowFlagForm(true);
                      }}
                      className={`cursor-pointer mx-6 p-2 flex justify-center items-center rounded-full shadow-lg ${
                        entry?.isFlagged ? "text-red-500" : "text-gray-500"
                      }`}
                    >
                      {entry?.isFlagged ? <IoFlag /> : <IoFlagOutline />}
                    </div>
                  </div>
                ))}
              </td>
            </tr>
          ))}
          {/* Add a total row */}
          <tr>
            <td className="border px-4 py-2 font-bold">Total</td>
            <td></td>
            <td className="border px-4 py-2 font-bold">
              {Object.values(groupedData)
                .reduce((sum, entry) => sum + entry.totalHours, 0)
                ?.toFixed(2)}{" "}
              Hours
            </td>
            <td className="border px-4 py-2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
