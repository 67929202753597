import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../Constants";

const TimesheetTable = ({
  timesheets,
  handleDelete,
  setFlaggedTimesheet,
  setDrawerOpen,
}) => {
  const [filterTask, setFilterTask] = useState("");
  const [filterProject, setFilterProject] = useState("");
  const [filterDate, setFilterDate] = useState("");
  const [expandedId, setExpandedId] = useState(null);

  const filteredTimesheets = timesheets.filter((timesheet) => {
    const taskMatch = timesheet.field_entrytask
      .toLowerCase()
      .includes(filterTask.toLowerCase());
    const projectMatch = timesheet.field_proj?.projectName
      .toLowerCase()
      .includes(filterProject.toLowerCase());
    const dateMatch = timesheet.field_entrydate.includes(filterDate);

    return taskMatch && projectMatch && dateMatch;
  });

  // Function to group timesheets by project and calculate total time spent
  const groupByProject = (timesheets) => {
    return timesheets.reduce((acc, timesheet) => {
      const projectName =
        timesheet.field_proj?.projectName || "Unknown Project";
      const timeSpent = parseFloat(timesheet.field_time_spent) || 0;

      if (!acc[projectName]) {
        acc[projectName] = {
          timesheets: [],
          totalSpent: 0,
        };
      }
      acc[projectName].timesheets.push(timesheet);
      acc[projectName].totalSpent += timeSpent;

      return acc;
    }, {});
  };

  const groupedTimesheets = groupByProject(filteredTimesheets);

  // Calculate grand total
  const grandTotal = Object.values(groupedTimesheets).reduce(
    (total, project) => total + project.totalSpent,
    0
  );

  return (
    <div className="overflow-x-auto">
      {filteredTimesheets.length > 0 ? (
        <table className="min-w-full bg-white border border-gray-300 shadow-lg rounded-lg">
          <thead className="bg-gray-200">
            <tr>
              <th className="border px-4 py-2 text-left font-semibold text-gray-700">
                Entry Task
              </th>
              <th className="border px-4 py-2 text-left font-semibold text-gray-700">
                Entry Date
              </th>
              <th className="border px-4 py-2 text-left font-semibold text-gray-700">
                Ticket Number
              </th>
              <th className="border px-4 py-2 text-left font-semibold text-gray-700">
                Project Name
              </th>
              <th className="border px-4 py-2 text-left font-semibold text-gray-700">
                Description
              </th>
              <th className="border px-4 py-2 text-left font-semibold text-gray-700">
                Time Spent
              </th>
              <th className="border px-4 py-2 text-left font-semibold text-gray-700">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedTimesheets).map(
              ([projectName, { timesheets, totalSpent }]) => (
                <React.Fragment key={projectName}>
                  {/* Project Header */}
                  <tr className="bg-gray-300 font-bold">
                    <td colSpan="6" className="border px-4 py-2">
                      {projectName} (Total Time Spent: {totalSpent} hours)
                    </td>
                    <td className="border px-4 py-2" />
                  </tr>
                  {/* Timesheet Entries */}
                  {timesheets.map((timesheet) => (
                    <tr
                      key={timesheet._id}
                      onClick={() => {
                        if (timesheet.isFlagged) {
                          setFlaggedTimesheet(timesheet);
                          setDrawerOpen(true);
                        } else {
                          setExpandedId(
                            expandedId === timesheet._id ? null : timesheet._id
                          );
                        }
                      }}
                      className={`cursor-pointer transition-all duration-200 ${
                        timesheet.isFlagged ? "bg-red-100" : "bg-white"
                      } hover:bg-gray-100`}
                    >
                      <td className="border px-4 py-2 capitalize">
                        {timesheet.field_entrytask}
                      </td>
                      <td className="border px-4 py-2">
                        {timesheet.field_entrydate}
                      </td>
                      <td className="border px-4 py-2">
                        {timesheet.field_ticket_number}
                      </td>
                      <td className="border px-4 py-2">
                        {timesheet.field_proj?.projectName}
                      </td>
                      <td className="border px-4 py-2">{timesheet.body}</td>
                      <td className="border px-4 py-2">
                        {timesheet.field_time_spent}
                      </td>
                      <td className="border px-4 py-2 text-center">
                        <button
                          className="text-red-500 hover:underline"
                          onClick={(e) => {
                            handleDelete(timesheet._id, timesheet?.wup_nid);
                            e.stopPropagation();
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              )
            )}
          </tbody>
          <tfoot>
            <tr className="bg-gray-300 font-bold">
              <td colSpan="5" className="border px-4 py-2 text-right">
                Grand Total
              </td>
              <td className="border px-4 py-2">{grandTotal} hours</td>
              <td className="border px-4 py-2" />
            </tr>
          </tfoot>
        </table>
      ) : (
        <div className="flex flex-col justify-center items-center">
          <img
            src={require("../../assets/not-fond.webp")}
            alt="No Timesheets"
            className="h-32 w-32 object-cover"
          />
          <p className="text-gray-600">
            No timesheets found for the selected criteria.
          </p>
        </div>
      )}
    </div>
  );
};

export default TimesheetTable;
