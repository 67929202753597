import { SlPencil } from "react-icons/sl"; // Import SlPencil
import { FaRegClone, FaTrash } from "react-icons/fa"; // Import FaTrash
import React from "react";
import { Tooltip } from "../UserDashboard";

// Assuming savedEntries is an array of entry objects
const SavedEntriesTable = ({
  savedEntries,
  handleEdit,
  handleDelete,
  handleClone,
}) => {
  // Function to group entries by date and calculate total hours
  const groupedEntries = savedEntries.reduce((acc, entry) => {
    const date = entry.field_entrydate; // Get the date
    const hours = parseFloat(entry.field_time_spent) || 0; // Get the hours

    if (!acc[date]) {
      acc[date] = { totalHours: 0, entries: [] }; // Initialize if not present
    }

    acc[date].totalHours += hours; // Sum the hours
    acc[date].entries.push(entry); // Add entry to the date group

    return acc;
  }, {});

  // Convert the grouped object into an array and sort by date
  const sortedGroupedEntries = Object.keys(groupedEntries)
    .sort()
    .map((date) => ({
      date,
      totalHours: groupedEntries[date].totalHours,
      entries: groupedEntries[date].entries,
    }));

  // Calculate the overall total hours
  const overallTotalHours = sortedGroupedEntries.reduce(
    (sum, group) => sum + group.totalHours,
    0
  );

  return (
    <div>
      <div className="border p-2 flex justify-between mt-6  bg-[#213251] px-10 rounded-tl-lg rounded-tr-lg text-white">
        <h2 className="text-base font-semibold font-montserrat ">
          Saved Entries
        </h2>
        <h2 className="text-base font-semibold font-montserrat  ">
          Total Hours: {overallTotalHours.toFixed(2)}
        </h2>
      </div>
      <table className="w-full border text-center bg-gray-100">
        <thead className="bg-gray-200 font-montserrat text-sm">
          <tr>
            <th className="border p-2">Project</th>
            <th className="border p-2">Task</th>
            <th className="border p-2">Date</th>
            <th className="border p-2">Ticket Number</th>
            <th className="border p-2">Time Spent</th>
            <th className="border p-2">Description</th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody className="text-sm font-opensans">
          {sortedGroupedEntries.map((group, index) => (
            <React.Fragment key={index}>
              <tr>
                <td
                  colSpan="8"
                  className="border p-2 font-semibold bg-[#077fc0]  text-white text-start"
                >
                  <pre>
                    Date: {group.date}, Total Hours:{" "}
                    {group.totalHours.toFixed(2)}
                  </pre>
                </td>
              </tr>
              {group.entries.map((entry, entryIndex) => (
                <tr key={entryIndex} className="hover:bg-gray-50">
                  <td className="border p-2">
                    {entry?.field_proj?.projectName}
                  </td>
                  <td className="border p-2 capitalize">
                    {entry?.field_entrytask}
                  </td>
                  <td className="border p-2 capitalize">
                    {entry?.field_entrydate}
                  </td>
                  <td className="border p-2 capitalize">
                    {entry?.field_ticket_number}
                  </td>
                  <td className="border p-2 capitalize">
                    {entry?.field_time_spent}
                  </td>
                  <td className="border p-2 capitalize">
                    {entry?.isHtml ? (
                      <div dangerouslySetInnerHTML={{ __html: entry?.body }} />
                    ) : (
                      entry?.body
                    )}
                  </td>
                  <td className="border p-2 capitalize flex justify-center items-center gap-4">
                    <Tooltip text="Edit Entry">
                      <button
                        onClick={() => handleEdit(entry, entry?._id)}
                        className="rounded mr-2"
                      >
                        <SlPencil className="text-xs text-white bg-[#ff480f] rounded-full w-[2rem] h-[2rem] p-[.5rem]" />
                      </button>
                    </Tooltip>

                    <Tooltip text="Clone Entry">
                      <button
                        onClick={() => handleClone(entry, entry?._id)}
                        className="py-1 px-2 text-gray-100 bg-[#B2a997] rounded-full w-[2rem] h-[2rem]"
                      >
                        <FaRegClone />
                      </button>
                    </Tooltip>
                    <Tooltip text="Delete Entry">
                      <button
                        onClick={() =>
                          handleDelete(entryIndex, entry?._id, entry?.wup_nid)
                        }
                        className="py-1 px-2 text-gray-100 bg-[#077fc0] rounded-full w-[2rem] h-[2rem]"
                      >
                        <FaTrash />
                      </button>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SavedEntriesTable;
