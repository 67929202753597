import React, { useEffect, useState } from "react";
import axios from "axios";
import PermissionDenied from "../Permission/PermissionDenied";
import { PERMISSIONS } from "../PermissionsConstant";

// Interface for the Project response

const ProjectList = ({ projects }) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return permissions?.includes(
    PERMISSIONS.PROJECT_PERMISSIONS.VIEW_PROJECTS
  ) ? (
    <div className="max-w-5xl mx-auto p-8 text-[#333333]">
      <h1 className="text-2xl font-semibold mb-6 font-montserrat">
        Project List
      </h1>

      <div className="grid grid-cols-1 gap-6">
        {projects?.length > 0 ? (
          projects?.map((project) => (
            <div
              key={project?._id}
              className="border border-gray-300 rounded-md shadow p-4"
            >
              <h2 className="text-xl font-semibold font-montserrat">
                {project?.projectName}
              </h2>
              <p className="text-sm text-gray-500">Type: {project?.type}</p>
              <p className="text-sm text-gray-500">
                Start Date:{" "}
                {new Date(project?.startDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
              <p className="text-sm text-gray-500">Status: {project.status}</p>
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-500">No projects available</p>
        )}
      </div>
    </div>
  ) : (
    <PermissionDenied />
  );
};

export default ProjectList;
