import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import { BASE_URL } from "../../Constants";

const CreateProjectForm = ({ refetch, handleClose }) => {
  // State for form fields
  const [projectName, setProjectName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("active");
  const [type, setType] = useState("engineering");
  const [wup_pid, setWupPid] = useState("");
  const [users, setUsers] = useState([]); // Array of user IDs

  const [selectedProject, setSelectedProject] = useState("");
  const [projects, setProjects] = useState([]);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/project/projects`);
      setProjects(response.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    // Fetch project data from API
    fetchProjects();
  }, []);

  // Form submission handler
  const handleSubmit = async (event) => {
    event.preventDefault();

    const projectData = {
      projectName,
      startDate,
      endDate: endDate || null, // Optional end date
      status,
      type,
      wup_pid,
      users, // Array of user IDs
    };
    try {
      // Send a POST request to create the project
      const response = await axios.post(
        `${BASE_URL}/api/project/createProjects`,
        projectData
      ); // Replace with your actual API endpoint
      console.log("Project created:", response.data);
      // Optionally, clear the form or give success feedback
      setProjectName("");
      setStartDate("");
      setEndDate("");
      setStatus("");
      setType("");
      setUsers([]);
      if (response.status === 200 || response.status === 201) {
        refetch();
        handleClose(false);
      }
    } catch (error) {
      console.error("Error creating project:", error);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-2 text-[#333333]">
      <div className="flex row justify-between">
        <h1 className="text-2xl font-bold mb-6 font-montserrat">
          Create Project
        </h1>

        <IoClose onClick={() => handleClose(false)} />
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-gray-700 font-montserrat">
            Project Name<sup className="text-red-500">*</sup>
          </label>
          <input
            type="text"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            required
          />
        </div>

        <div className="flex gap-4">
          <div className="flex-1">
            <label className="block text-gray-700 font-montserrat">
              Start Date<sup className="text-red-500">*</sup>
            </label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              required
            />
          </div>

          <div className="flex-1">
            <label className="block text-gray-700 font-montserrat">
              End Date (optional)
            </label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1"
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="flex-1">
            <label className="block text-gray-700 font-montserrat">
              Status<sup className="text-red-500">*</sup>
            </label>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1"
            >
              <option value="active">Active</option>
              <option value="completed">Completed</option>
              <option value="on-hold">On Hold</option>
              <option value="pending">Pending</option>
              <option value="ongoing">Ongoing</option>
            </select>
          </div>

          <div className="flex-1">
            <label className="block text-gray-700 font-montserrat">
              Type<sup className="text-red-500">*</sup>
            </label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1"
            >
              <option value="engineering">Engineering</option>
              <option value="human_resource">Human Resource</option>
              <option value="management">Management</option>
              <option value="operations">Operations</option>
            </select>
          </div>
        </div>

        {/* <div>
          <label className="block text-gray-700">Users (IDs)</label>
          <input
            type="text"
            value={users.join(",")}
            onChange={(e) =>
              setUsers(e.target.value.split(",").map((id) => id.trim()))
            }
            className="w-full p-2 border border-gray-300 rounded mt-1"
            placeholder="Enter user IDs separated by commas"
          />
        </div> */}

        <div>
          <label className="block text-gray-700 font-montserrat">
            Whats'up Project Id{" "}
            <sup className="text-red-500 font-montserrat">*</sup>
          </label>
          <input
            type="text"
            value={wup_pid}
            onChange={(e) => setWupPid(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mt-1"
            placeholder="Whats'up Project Id"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white p-3 rounded mt-4 font-medium font-montserrat "
        >
          Create Project
        </button>
      </form>
    </div>
  );
};

export default CreateProjectForm;
