import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENTID } from './auth/constants';
import { Detector, Offline, Online } from 'react-detect-offline';
import OfflineComponent from './components/OfflineComponent/Offline';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Detector
    render={({ online }) =>
      online ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENTID}>
            <App />
          </GoogleOAuthProvider>
        </LocalizationProvider>
      ) : (
        <OfflineComponent />
      )
    }
  />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
