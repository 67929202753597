import React from "react";
import exportToCsv from "json-to-csv-export";

const ExportCsvDateWise = ({
  jsonData,
  username = "all",
  start_date,
  end_date,
  project = "all",
}) => {
  const handleExportCsv = () => {
    const csvData = [];

    // Check if jsonData is valid
    if (!jsonData || typeof jsonData !== "object") {
      console.error("Invalid jsonData:", jsonData);
      return; // Early return if jsonData is not valid
    }

    // Iterate over each date in jsonData
    for (const date in jsonData) {
      if (jsonData.hasOwnProperty(date)) {
        const data = jsonData[date];
        const totalHours = data.totalHours;

        // Check if entries are present and valid
        if (!data.entries || !Array.isArray(data.entries)) {
          console.warn("No entries found for date:", date);
          continue; // Skip if no entries are present
        }

        // Iterate over each entry
        data.entries.forEach((entry) => {
          const project = entry.field_proj
            ? entry.field_proj.projectName
            : "No Project";
          csvData.push({
            Date: date,
            Project: project,
            Task: entry.field_entrytask,
            Description: entry.body,
            TimeSpent: entry.field_time_spent,
            User: `${entry.uid.fname} ${entry.uid.lname}`,
            Email: entry.uid.email,
            TotalHours: totalHours, // Include total hours for this date
          });
        });
      }
    }

    // Check if csvData is valid before exporting
    if (csvData.length === 0) {
      console.warn("No valid CSV data to export");
      return; // No data to export
    }

    // Add headers
    const headers = {
      Date: "Date",
      Project: "Project",
      Task: "Task",
      Description: "Description",
      TimeSpent: "Time Spent (hours)",
      User: "User",
      Email: "Email",
      TotalHours: "Total Hours",
    };

    // Export to CSV
    exportToCsv(
      csvData,
      headers,
      `timesheet_entries_${username}_${start_date}_${end_date}_${project}.csv`
    );
  };

  // Function to export data to CSV
  const exportToCsv = (data, headers, filename) => {
    // Create CSV content
    const csvContent = [
      Object.keys(headers).join(","), // header row
      ...data.map((row) => Object.values(row).join(",")), // data rows
    ].join("\n");

    // Create a blob and download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <button
        className="p-2 bg-[#ff480f]  px-5 text-sm text-white rounded"
        onClick={handleExportCsv}
      >
        Export Timesheet CSV
      </button>
    </div>
  );
};

export default ExportCsvDateWise;
