export const PERMISSIONS = {
  USER_PERMISSIONS: {
    VIEW_USER_INFORMATION: 'view_user_information',
    VIEW_USER_LIST: 'view_user_list',
    CREATE_USER_INFORMATION: 'create_new_user',
    EDIT_USER_INFORMATION: 'edit_user_information',
    ASSIGN_ROLES: 'assign_roles',
    DEACTIVATE_USERS: 'deactivate_users',
  },
  PROJECT_PERMISSIONS: {
    VIEW_PROJECTS: 'view_projects',
    EDIT_PROJECT: 'edit_project',
    ASSIGN_USERS_TO_PROJECTS: 'assign_users_to_projects',
    CREATE_NEW_PROJECT: 'create_new_project',
    DELETE_PROJECT: 'delete_project',
    VIEW_PROJECT_TASKS: 'view_project_tasks',
    MANAGE_PROJECT_ROLES: 'manage_project_roles',
    PROJECT_MANAGER_VIEW: 'project_manager_view',
  },
  TASK_PERMISSIONS: {
    VIEW_TASKS: 'view_tasks',
    CREATE_TASK: 'create_task',
    EDIT_TASK: 'edit_task',
    ASSIGN_TASKS: 'assign_tasks',
    DELETE_TASK: 'delete_task',
  },
  TIMESHEET_PERMISSIONS: {
    VIEW_TIMESHEET_ENTRIES: 'view_timesheet_entries',
    EDIT_TIMESHEET_ENTRIES: 'edit_timesheet_entries',
    SUBMIT_TIMESHEET: 'submit_timesheet',
    APPROVE_REJECT_TIMESHEET: 'approve_reject_timesheet',
    DELETE_TIMESHEET_ENTRIES: 'delete_timesheet_entries',
  },
  DATA_FILTERING_REPORTING_PERMISSIONS: {
    VIEW_DATA_FILTERS: 'view_data_filters',
    GENERATE_REPORTS: 'generate_reports',
    EXPORT_DATA: 'export_data',
  },
  ADMINISTRATIVE_PERMISSIONS: {
    MANAGE_PERMISSIONS: 'manage_permissions',
    SYSTEM_CONFIGURATION: 'system_configuration',
    VIEW_AUDIT_LOGS: 'view_audit_logs',
  },
};
