import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoClose, IoTrash, IoPencil } from "react-icons/io5";
import { BASE_URL } from "../../Constants";
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import { employmentStatuses, roles } from "./CreateUserComponent";
import { PERMISSIONS } from "../PermissionsConstant";

const UserList = ({ users, refetch }) => {
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editUserData, setEditUserData] = useState({});
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const unlinkProjectFromUser = async (userId, projectId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/user/unlinkProjectFromUser/${userId}/removeProject`,
        { projectId }
      );
      console.log("Association removed successfully:", response.data);
      alert("Association removed successfully!");
      const updatedProjects = showUserDetails?.projects?.filter(
        (proj) => proj?.projectId?._id !== projectId
      );
      console.log("Updated Project", updatedProjects);
      setShowUserDetails({ ...showUserDetails, projects: updatedProjects });
      refetch();
    } catch (error) {
      console.error(
        "Error removing association:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const linkProject = async (projectId, role) => {
    if (!projectId || projectId === "") {
      alert("Please choose project");
      return;
    }
    if (!role || role === "") {
      alert("Please choose role");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/user/linkProjectToUser/${showUserDetails?._id}/addProject`,
        { projectId, role }
      );
      if (response.status === 200) {
        setLoading(false);

        setShowUserDetails(null);
        alert("Project successfully linked to the user!");
        refetch();
      }
    } catch (error) {
      console.error(
        "Error linking project:",
        error.response ? error.response.data : error
      );
      alert("Failed to link the project to the user.");
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/project/projects`);
      setProjectList(response.data);
    } catch (err) {
      console.error("Error fetching projects:", err);
      setError("Error fetching projects.");
    }
  };
  const generateColor = (index) => {
    const hue = (index * 137.508) % 360;
    return `hsl(${hue}, 70%, 80%)`;
  };
  const handleAddRole = (e) => {
    const newRole = e.target.value;
    if (newRole && !editUserData.roles.includes(newRole)) {
      setEditUserData((prev) => ({ ...prev, roles: [...prev.roles, newRole] }));
    }
  };
  const validateForm = () => {
    let errors = {};
    if (!editUserData.fname) errors.fname = "First name is required.";
    if (!editUserData.lname) errors.lname = "Last name is required.";
    if (!editUserData.wup_username)
      errors.wup_username = "Wup username is required.";
    if (!editUserData.employeeId)
      errors.employeeId = "Employee ID is required.";
    if (editUserData.roles.length === 0)
      errors.roles = "At least one role is required.";
    if (!editUserData.employmentStatus)
      errors.employmentStatus = "Employment status is required.";

    setErrors(errors);
    return Object.keys(errors).length === 0; // return true if no errors
  };

  // Handle removing a role
  const handleRemoveRole = (roleToRemove) => {
    setEditUserData((prev) => ({
      ...prev,
      roles: prev.roles.filter((role) => role !== roleToRemove),
    }));
  };
  const handleUpdateUser = async () => {
    if (!validateForm()) return; //
    console.log("Update form", editUserData);
    const updatePayload = {
      fname: editUserData?.fname,
      lname: editUserData?.lname,
      employeeId: editUserData?.employeeId,
      email: editUserData?.email,
      isActive: editUserData?.isActive,
      wup_uid: editUserData?.wup_uid,
      wup_username: editUserData?.wup_username,
      roles: editUserData?.roles,
      employmentStatus: editUserData?.employmentStatus,
    };
    try {
      setLoading(true);
      await axios.put(
        `${BASE_URL}/api/user/updateUser/${editUserData._id}`,
        updatePayload
      );
      alert("User details updated successfully");
      setIsEditing(false);
      setShowUserDetails(null);
      refetch();
      // Close the modal after updating
    } catch (error) {
      console.error("Error updating user:", error.response || error);
      alert("Failed to update user.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmDelete) return;

    try {
      setLoading(true);
      await axios.delete(`${BASE_URL}/api/user/deleteUser/${userId}`);
      alert("User deleted successfully.");
      refetch();
    } catch (error) {
      console.error("Error deleting user:", error.response || error);
      alert("Failed to delete user.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (showUserDetails) fetchProjects();
  }, [showUserDetails]);

  const openEditModal = (user) => {
    setEditUserData({ ...user });
    setIsEditing(true);
  };
  const [searchTerm, setSearchTerm] = useState("");

  // Filter users based on search term
  const filteredUsers = users?.filter((user) => {
    const fullName = `${user?.fname} ${user?.lname}`.toLowerCase();
    const email = user?.email?.toLowerCase();
    const employeeId = user?.employeeId?.toLowerCase();

    return (
      fullName.includes(searchTerm.toLowerCase()) ||
      email.includes(searchTerm.toLowerCase()) ||
      employeeId.includes(searchTerm.toLowerCase())
    );
  });
  return (
    <div className="max-w-7xl mx-auto  overflow-x-auto">
      <h1 className="text-2xl font-semibold mb-6 font-montserrat">User List</h1>
      {loading && <ProgressLoader />}
      {users?.length === 0 && <p>No users available</p>}
      <div>
        {/* Search bar */}
        <input
          type="text"
          placeholder="Search by name, email, or employee ID"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-4 p-2 border border-gray-300 rounded w-full font-montserrat"
        />

        {/* Table */}
        <table className="table-auto w-full border-collapse border border-gray-300 shadow-lg text-sm">
          <thead className="font-montserrat">
            <tr className="bg-gray-100 text-left">
              <th className="border p-4">Name</th>
              <th className="border p-4">Email</th>
              <th className="border p-4">Role</th>
              <th className="border p-4">Employee ID</th>
              <th className="border p-4">Employment Status</th>
              <th className="border p-4">Active</th>
              <th className="border p-4">Projects</th>
              <th className="border p-4">Actions</th>
            </tr>
          </thead>
          <tbody className="text-xs font-opensans">
            {filteredUsers?.map((user, index) => (
              <tr
                onClick={(e) => {
                  e.stopPropagation();
                  setShowUserDetails(user);
                }}
                key={user._id}
                className={`${
                  index % 2 === 0 ? "bg-white" : "bg-gray-50"
                } hover:bg-gray-100 cursor-pointer`}
              >
                <td className="border p-4">{`${user?.fname} ${user?.lname}`}</td>
                <td className="border p-4">{user?.email}</td>
                <td className="border p-4">{user?.roles?.join(", ")}</td>
                <td className="border p-4">{user?.employeeId}</td>
                <td className="border p-4 capitalize">
                  {user?.employmentStatus}
                </td>
                <td className="border p-4">{user?.isActive ? "Yes" : "No"}</td>
                <td className="border p-4">
                  {user.projects.length ? (
                    <ul className="list-disc pl-4">
                      {user.projects.map((project) => (
                        <li key={project?.projectId?._id}>
                          {project?.projectId?.projectName}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "No projects assigned"
                  )}
                </td>
                <td className="  p-4 flex space-x-2 self-center items-center justify-center ">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      openEditModal(user);
                    }}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    <IoPencil className="text-xl" />
                  </button>
                  <button
                    onClick={() => handleDeleteUser(user._id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <IoTrash className="text-xl" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isEditing && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800/80 z-10 text-[#333333]">
          <div className="w-2/3 bg-white rounded-lg p-6 shadow-xl">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold font-montserrat">
                Edit User
              </h2>
              <IoClose
                className="text-xl cursor-pointer"
                onClick={() => setIsEditing(false)}
              />
            </div>

            <form>
              <div className="flex gap-4">
                <div className="mb-4 flex-1">
                  <label className="block font-semibold mb-2 font-montserrat">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    value={editUserData.fname}
                    onChange={(e) =>
                      setEditUserData({
                        ...editUserData,
                        fname: e.target.value,
                      })
                    }
                  />
                  {errors.fname && (
                    <p className="text-red-500 text-sm">{errors.fname}</p>
                  )}
                </div>

                <div className="mb-4 flex-1">
                  <label className="block font-semibold mb-2 font-montserrat">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    value={editUserData.lname}
                    onChange={(e) =>
                      setEditUserData({
                        ...editUserData,
                        lname: e.target.value,
                      })
                    }
                  />
                  {errors.lname && (
                    <p className="text-red-500 text-sm">{errors.lname}</p>
                  )}
                </div>
              </div>

              <div className="flex gap-4">
                <div className="mb-4 flex-1">
                  <label className="block font-semibold mb-2 font-montserrat">
                    WUP UID
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    value={editUserData.wup_username}
                    onChange={(e) =>
                      setEditUserData({
                        ...editUserData,
                        wup_username: e.target.value,
                      })
                    }
                  />
                  {errors.wup_username && (
                    <p className="text-red-500 text-sm">
                      {errors.wup_username}
                    </p>
                  )}
                </div>

                <div className="mb-4 flex-1">
                  <label className="block font-semibold mb-2 font-montserrat">
                    Employee ID
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    value={editUserData.employeeId}
                    onChange={(e) =>
                      setEditUserData({
                        ...editUserData,
                        employeeId: e.target.value,
                      })
                    }
                  />
                  {errors.employeeId && (
                    <p className="text-red-500 text-sm">{errors.employeeId}</p>
                  )}
                </div>
              </div>

              <div className="flex gap-4">
                <div className="mb-4 flex-1">
                  <label className="block font-semibold mb-2 font-montserrat">
                    WUP Username
                  </label>
                  <input
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-lg"
                    value={editUserData.wup_uid}
                    onChange={(e) =>
                      setEditUserData({
                        ...editUserData,
                        wup_uid: e.target.value,
                      })
                    }
                  />
                  {errors.wup_uid && (
                    <p className="text-red-500 text-sm">{errors.wup_uid}</p>
                  )}
                </div>

                <div className="flex-1 mb-4">
                  <label className="block   font-semibold text-gray-700 font-montserrat">
                    Roles:<sup className="text-red-500">*</sup>
                  </label>
                  <div className="flex flex-wrap ">
                    {editUserData.roles.map((role) => (
                      <div
                        key={role}
                        className="flex items-center bg-gray-200 rounded-full px-4 py-1 mr-2"
                      >
                        <span>{role}</span>
                        <IoClose
                          onClick={() => handleRemoveRole(role)}
                          className="ml-2 text-xl cursor-pointer text-red-500"
                        />
                      </div>
                    ))}
                  </div>

                  <select
                    onChange={handleAddRole}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 "
                  >
                    <option value="">Select Role</option>
                    {roles?.map((role) => (
                      <option key={role} value={role}>
                        {role}
                      </option>
                    ))}
                  </select>
                  {errors.roles && (
                    <p className="text-red-500 text-sm">{errors.roles}</p>
                  )}
                </div>
              </div>

              <div className="flex-1 mb-4">
                <label className="block  font-medium  font-montserrat">
                  Employment Status:<sup className="text-red-500">*</sup>
                </label>
                <select
                  className="mt-1 block  w-1/2 border border-gray-300 rounded-md shadow-sm p-2 "
                  name="employmentStatus"
                  value={editUserData?.employmentStatus}
                  onChange={(e) =>
                    setEditUserData({
                      ...editUserData,
                      employmentStatus: e.target.value,
                    })
                  }
                >
                  <option value="">Select Status</option>
                  {employmentStatuses?.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
                {errors.employmentStatus && (
                  <p className="text-red-500 text-sm">
                    {errors.employmentStatus}
                  </p>
                )}
              </div>

              <div className="flex items-center py-4">
                <input
                  type="checkbox"
                  name="isActive"
                  checked={editUserData.isActive}
                  onChange={() =>
                    setEditUserData({
                      ...editUserData,
                      isActive: !editUserData.isActive,
                    })
                  }
                  className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
                <label className="ml-2 block text-sm text-gray-900">
                  Is Active
                </label>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-[#077fc0] text-white py-2 px-4 rounded-lg hover:bg-[#077fc0] font-montserrat"
                  onClick={handleUpdateUser}
                >
                  Update User
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showUserDetails && (
        <div className="">
          <div className="fixed inset-0 flex justify-center items-center bg-gray-800/80 z-10">
            <div className="w-1/2 rounded-xl p-8 bg-white shadow-xl">
              <div className="flex justify-between mb-4">
                <p className="font-bold text-2xl text-gray-800">
                  Manage Projects
                </p>
                <IoClose
                  onClick={() => setShowUserDetails(null)}
                  className="text-xl cursor-pointer hover:bg-gray-200 rounded-full"
                />
              </div>

              <div className="space-y-2">
                <p>
                  <strong>Name:</strong> {showUserDetails?.fname}
                </p>
                <p>
                  <strong>Email:</strong> {showUserDetails?.email}
                </p>
                <hr className="my-4" />

                {permissions?.includes(
                  PERMISSIONS.PROJECT_PERMISSIONS.VIEW_PROJECTS
                ) && (
                  <div className="my-5">
                    <p className="font-bold text-lg text-gray-800">
                      Current Projects
                    </p>
                    {console.log(showUserDetails)}
                    <div className="flex flex-wrap mt-2  relative gap-2">
                      {showUserDetails?.projects?.map((project, index) => (
                        <div
                          key={project?.projectId?._id}
                          style={{ backgroundColor: generateColor(index) }}
                          className="flex   flex-col text-xs rounded-xl p-2 px-4 shadow-md relative w-auto "
                        >
                          <div className="flex gap-4">
                            <span className="font-bold">
                              {project?.projectId?.projectName}
                            </span>
                            <button
                              type="button"
                              onClick={() =>
                                unlinkProjectFromUser(
                                  showUserDetails?._id,
                                  project?.projectId?._id
                                )
                              }
                            >
                              <IoClose className="text-2xl p-1 text-red-600 hover:bg-gray-100 rounded-full  " />
                            </button>
                          </div>
                          <span>{project?.role}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {permissions?.includes(
                  PERMISSIONS.PROJECT_PERMISSIONS.ASSIGN_USERS_TO_PROJECTS
                ) && (
                  <div className="mb-5">
                    <label className="font-bold text-lg text-gray-800 mb-2 block">
                      Assign Project
                    </label>
                    <select
                      className="block w-full p-2 border border-gray-300 rounded-lg"
                      onChange={(e) => setSelectedProject(e.target.value)}
                      value={selectedProject || ""}
                    >
                      <option value="">Select Project</option>
                      {projectList.map((project) => (
                        <option key={project._id} value={project._id}>
                          {project.projectName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {permissions?.includes(
                  PERMISSIONS.PROJECT_PERMISSIONS.MANAGE_PROJECT_ROLES
                ) && (
                  <div className="mb-5">
                    <label className="font-bold text-lg text-gray-800 mb-2 block">
                      Assign Role
                    </label>
                    <select
                      className="block w-full p-2 border border-gray-300 rounded-lg"
                      onChange={(e) => setSelectedRole(e.target.value)}
                      value={selectedRole}
                    >
                      <option value="">Select Role</option>
                      <option value="Project Manager">Project Manager</option>
                      <option value="Developer">Developer</option>
                      <option value="QA">QA</option>
                      <option value="TA">TA</option>
                      <option value="HR">HR</option>
                      <option value="Operations">Operations</option>
                      <option value="Account">Account</option>
                      <option value="Marketing/PR">Marketing/PR</option>
                      <option value="Branding/Design">Branding/Design</option>
                    </select>
                  </div>
                )}

                {permissions.includes(
                  PERMISSIONS.PROJECT_PERMISSIONS.MANAGE_PROJECT_ROLES
                ) &&
                  permissions.includes(
                    PERMISSIONS.PROJECT_PERMISSIONS.ASSIGN_USERS_TO_PROJECTS
                  ) && (
                    <button
                      className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg w-full"
                      onClick={() => linkProject(selectedProject, selectedRole)}
                    >
                      Add Project
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserList;
