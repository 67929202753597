import React, { useState, useEffect } from "react";
import axios from "axios";
import { IoAdd, IoClose } from "react-icons/io5";
import { Button, Tooltip } from "@mui/material";
import { BASE_URL } from "../../Constants";
import { PERMISSIONS } from "../PermissionsConstant";
import PermissionDenied from "../Permission/PermissionDenied";

const TaskComponent = () => {
  const [tasks, setTasks] = useState([]);
  const [title, setTitle] = useState("");
  const [shortForm, setShortForm] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [error, setError] = useState("");
  const [wup_tid, setWupTid] = useState("");
  const [success, setSuccess] = useState("");

  // Categories for the task
  const categories = [
    "development",
    "design",
    "documentation",
    "testing",
    "management",
    "meeting",
    "other",
  ];

  // Fetch all tasks
  const fetchAllTasks = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/taskrouter/tasks`);
      setTasks(response.data);
    } catch (err) {
      console.error("Error fetching tasks:", err);
    }
  };

  // Handle form submission for creating a task
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title || !shortForm || !description || !category) {
      setError("Please fill in all fields.");
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/api/taskrouter/tasks`, {
        title,
        shortForm,
        description,
        category,
        wup_tid,
      });

      setSuccess("Task created successfully!");
      setError("");
      // Fetch all tasks again to include the newly created task
      fetchAllTasks();
      // Clear form fields
      setTitle("");
      setShortForm("");
      setDescription("");
      setCategory("");
      setWupTid("");
    } catch (err) {
      setError("Failed to create task. Please try again.");
      setSuccess("");
    }
  };

  useEffect(() => {
    fetchAllTasks();
  }, []);

  // const permissions = localStorage.getItem("permissions");
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  console.log("Permissions");
  return permissions.includes(PERMISSIONS.TASK_PERMISSIONS.VIEW_TASKS) ? (
    <div className="container mx-auto p-4 text-[#333333]">
      <h1 className="text-3xl font-bold mb-4 font-montserrat">
        Task Management
      </h1>

      {/* Task List */}
      <div className="bg-white p-4 mb-6 rounded">
        {tasks.length === 0 ? (
          <p>No tasks available.</p>
        ) : (
          <ul className="space-y-4">
            {tasks.map((task) => (
              <li
                key={task._id}
                className="p-4 border border-gray-300 rounded text-sm"
              >
                <h3 className="text-base font-semibold font-montserrat">
                  {task.title}
                </h3>
                <p>
                  <strong>Short Form:</strong> {task.shortForm}
                </p>
                <p>
                  <strong>Description:</strong> {task.description}
                </p>
                <p>
                  <strong>Category:</strong> {task.category}
                </p>
                <p>
                  <strong>Created At:</strong>{" "}
                  {new Date(task.createdAt).toLocaleDateString()}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>

      {permissions.includes(PERMISSIONS.TASK_PERMISSIONS.CREATE_TASK) && (
        <button onClick={() => setShowForm(true)}>
          <IoAdd className="text-xl font-bold bg-[#FF480F] text-white rounded-xl p-1 w-[3rem] h-[3rem] fixed right-20 bottom-20" />
        </button>
      )}

      {/* Create Task Form */}
      {showForm && (
        <div className="bg-gray-800/80 fixed left-0 right-0 top-0 bottom-0 flex justify-center items-center">
          <div className="bg-white w-1/2 shadow-md p-10 rounded-xl">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold mb-2 font-montserrat">
                Create New Task
              </h2>
              <IoClose
                className="text-2xl cursor-pointer"
                onClick={() => setShowForm(false)}
              />
            </div>
            {error && <p className="text-red-500">{error}</p>}
            {success && <p className="text-green-500">{success}</p>}
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block font-medium mb-2 font-montserrat">
                    Title
                  </label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>

                <div>
                  <label className="block font-medium mb-2 font-montserrat">
                    Short Form
                  </label>
                  <input
                    type="text"
                    value={shortForm}
                    onChange={(e) => setShortForm(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>

                <div className="col-span-2">
                  <label className="block font-medium mb-2 font-montserrat">
                    Description
                  </label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full p-2 border rounded"
                    rows={4}
                  />
                </div>

                <div>
                  <label className="block font-medium mb-2 font-montserrat">
                    Category
                  </label>
                  <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Select Category</option>
                    {categories.map((cat) => (
                      <option key={cat} value={cat}>
                        {cat.charAt(0).toUpperCase() + cat.slice(1)}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block font-medium mb-2 font-montserrat">
                    Wup TaskId
                  </label>
                  <input
                    type="text"
                    value={wup_tid}
                    onChange={(e) => setWupTid(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>

                <div className="col-span-2 flex justify-end">
                  <button
                    type="submit"
                    className="bg-[#077fc0] text-white px-4 py-2 rounded font-montserrat font-medium"
                  >
                    Create Task
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div>
      <PermissionDenied />
    </div>
  );
};

export default TaskComponent;
